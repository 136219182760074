import { graphql } from 'gatsby'
import React from 'react'

import { IndexQueryQuery } from '../../../types/graphql-types'
import Meta from '../../components/meta/meta'
import Layout from '../../components/layout/layout'
import LightboxWrapper from '../../components/lightbox/lightboxwrapper'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const Cs12: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta

  return (
    <Layout edition="01-2021" location={location}>
      <Meta site={meta} />
      <div className="content-inner shadow-lg">
        <div
          className="page-header"
          style={{ backgroundImage: 'url(../../img/lon-cs-header.jpg)' }}
        ></div>
        <article className="post">
          <h1>Store Locator new feature</h1>
          <p>
            The Store Locator is a user online service that Longines provides
            and like for other services we’re always improving its service
            quality.
          </p>
          <p>
            Indeed, so far, only contractual level III partners are listed on
            the website, those who can intervene in the movements.
          </p>
          <p>
            To extend the range of services provided to our customers, we will
            also include the partners of:
          </p>

          <ul>
            <li>
              <strong>level I (intervention on the bracelets/straps)</strong>
            </li>
            <li>
              <strong>level II (battery change)</strong>
            </li>
          </ul>

          <LightboxWrapper>
            <a href={'../../img/lon-cs.jpg'}>
              <img
                src={'../../img/lon-cs.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
            <a href={'../../img/lon-cs2.jpg'}>
              <img
                src={'../../img/lon-cs2.jpg'}
                className="img-fluid thumbnail"
              />
            </a>
          </LightboxWrapper>

          <div className="spacer"></div>

          <div className="row align-items-center justify-content-center">
            <div className="col-md-8">
              <div className="alert alert-light text-primary shadow">
                <div className="d-flex align-items-center">
                  <ion-icon
                    name="rocket-outline"
                    style={{ marginRight: '30px', fontSize: '36px' }}
                  ></ion-icon>
                  <div>
                    <h3>Go live ?</h3>
                    <p className="mb-0">
                      New partners on the Store will appear in June
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <blockquote>
            <div className="d-flex">
              <img
                src={'../../img/dani-heitor.jpg'}
                className="rounded-circle shadow-lg mr-3"
                style={{ width: '80px', height: '80px' }}
              />
              <div>
                <h4>Need further information?</h4>
                <p>
                  Please email{' '}
                  <a href="mailto:dani.heitor@longines.com">
                    dani.heitor@longines.com
                  </a>{' '}
                  and he will be glad to answer your questions and organize a
                  Skype call at your convenience.
                </p>
              </div>
            </div>
          </blockquote>
        </article>
      </div>
    </Layout>
  )
}

export default Cs12

export const pageQuery = graphql`
  query Cs12Query_01_2021 {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
      }
    }
  }
`
